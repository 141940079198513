import { MuiThemeProvider } from "@material-ui/core"
import { Container } from "@mui/material"
import React, { useState } from "react"
import { tawktolink } from "../../../api/tawkto-config"
import { theme } from "../../../assets/mui"
import useWindowDimensions from "../../../custom-hooks/useWindowDimensions"
import LoadingChatIndicator from "./LoadingChatIndicator"

const TawkToPageMini = () => {
  const [isLoading, setisLoading] = useState(true)
  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <div>{isLoading ? <LoadingChatIndicator /> : null}</div>
        <iframe
          width={"100%"}
          height={"390px"}
          src={tawktolink}
          frameBorder={0}
          onLoad={() => {
            setisLoading(false)
          }}
        ></iframe>
      </div>
    </MuiThemeProvider>
  )
}

export default TawkToPageMini
